.title{
    color: black;
    font-size: 50px;
}

.projectbody{
    height: 1000px;
}



.card{
    background-color: aliceblue;
    margin-top: 20px;
    margin-left: 100px;
    margin-right: 50px;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(240, 238, 238, 0.479);
}

.card:hover{
    transform:scale(1.1, 1.1);
}

.html{
    background-color: rgba(255, 102, 0, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
}

.css{
    background-color: rgba(0, 255, 255, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
    margin-left: 2px;
}

.php{
    background-color: rgba(0, 98, 255, 0);
    padding: 4px;
    border-radius: 10px;    
    color: #0072E5;
    margin-left: 2px;
}

.mysql{
    background-color: rgba(94, 255, 0, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
    margin-left: 2px;
}

.js{
    background-color: rgba(255, 47, 0, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
    margin-left: 2px;
}

.python{
    background-color: rgba(255, 47, 0, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
    margin-left: 2px;
}

.api{
    background-color: rgba(255, 47, 0, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
    margin-left: 2px;
}

.react{
    background-color: rgba(255, 47, 0, 0);
    padding: 4px;
    border-radius: 10px;
    color: #0072E5;
    margin-left: 2px;
}

.swift{    
        background-color: rgba(0, 255, 255, 0);
        padding: 4px;
        border-radius: 10px;
        color: #0072E5;
        margin-left: 2px;
}

.comingSoon:hover{
    cursor:not-allowed;
}
.cpp{
    background-color: rgba(0, 255, 255, 0);
        padding: 4px;
        border-radius: 10px;
        color: #0072E5;
        margin-left: 2px;
}

.cards{
    transition: transform .9s ease-in-out;
    
}

.cards:hover{
    transition: 0.5s;
    scale: 1.1;
}