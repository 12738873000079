.bar-title{
    font-weight: 700;
    font-size: 22px;
    margin: 0 0 20px 0;
}

.bar{
    background-color: rgba(177, 170, 170, 0.426);
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    border-radius: 10px;    
    margin-bottom: 20px;
    display: flex;
}

.bar-cpp{
    background-color: #007FFF;
    width: 40%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-ruby{
    background-color: #007FFF;
    width: 23%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-html{
    background-color: #007FFF;
    width: 30%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-swift{
    background-color: #007FFF;
    width: 15%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-php{
    background-color: #007FFF;
    width: 17%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-sql{
    background-color: #007FFF;
    width: 20%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-java{
    background-color: #007FFF;
    width: 45%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-js{
    background-color: #007FFF;
    width: 20%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-python{
    background-color: #007FFF;
    width: 10%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
}

.bar-powershell{
    background-color: #007FFF;
    width: 5%;
    color:white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    
}

.text{
    visibility: hidden;
    color: #000000;
    font-size: 10px;
}
.bar:hover  {    
    opacity: .8;
}
.bar:hover .text {
    visibility: visible;
    padding-left: 20%;
}