.timelineTitle{
    font-size: 30px;
    color: black;
}

.timelineRole{
    font-size: 20px;
    font-style: italic;
    color: black;
}

.timelineDescription{
    font-size: 18px;
    color: black;
}