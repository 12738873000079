/* NavBar*/
.nav {
    background-color: #fff;
    opacity: 90%;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: large;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(203, 199, 199, 0.2);
    color: black;
    top: 0;
    padding-right: 6px;
    margin-left: -6px;
    position: sticky;
    z-index: 101;
}

.nav-items{
    list-style: none;
    display: flex;
}

.nav-items > li {
    padding: 30px;
}
/* NavBar*/
li:hover{
    cursor: pointer;
}
.sidenav-img{
    border-radius: 10%;
    width: 50%;
}
.sidenav {
    background-color:#eeeef2; /* Black*/
    color: #21222A;
    padding-top: 60px; /* Place content 60px from the top */
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    transition: all, 0.35s;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(203, 199, 199, 0.2);
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .left-zone {    
    position: absolute;
    transition: all, 0.75s;
    width: 18%;
    margin-left: 2%;
    top: 5%;
    margin-top: 100px;
}
.link-items{
    display: flex;
    justify-content: center;
}
.link{
    background-color: rgba(177, 170, 170, 0.587);
    border-radius: 100px;
    padding: 5px 10px 5px 10px;
    margin-right: 10px;    
}

body{
    /*  background-color: #21222A; */
    background-color: #fff;
    color: rgb(8, 8, 8);
    
   /* background-image: url('bg.jpg') */
}


.content{
    color: aliceblue;
    position: absolute;
    top: 50%;
    left: 50%;
}

a, a:hover, a:active, a:focus {
    text-decoration: none;
    color: inherit
}


.typed{
    align-items: center;
    vertical-align: middle;
    font-size: 30px;
  }

  .typed .anim{
    color:rgb(251, 255, 0)
  }

.center{
    text-align: center;
    color:black;
    size: 30px;    
    margin-bottom: 40%;
}

.centerHeader{
    margin-top:10%;
    color:black;
    font-size: 70px;
    
}

.wrap{
    margin-right: 100px;
}


.align{
    display: inline;
    color:aliceblue;
    padding-left: 1%;
    padding-right: 1%;
}

/* .splitScreen {
    display: flex;
    flex-direction: row;
} */
.leftPane{
    width: 20%;
}
.rightPane{
    width: 80%;
}


.homeCard{
    background-color: #ededee;
}

.muiCard{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    height: 85%;
    /* background: linear-gradient(to right bottom,#007FFF, #0059B2) */
}