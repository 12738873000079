body{
}

.bodyStyle{
    background:#3b444b;
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

}

.header{
    display:flex;
}

.categories{
    color: #FFF;
    text-align:left; 
    font-size: 35px;
    margin-left:15%;
}

.gridLayout{
    justify-content: center;
    width: 100%;
    margin-left: 50px;
}

.description{
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color:#FFF;
    background: linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178)) 120% center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 9px;
    font-size: 20px;
    border-radius: 20px;

}

@media screen and (max-width: 400px){
	/* Mobile Responsiveness CSS here */
	.gridLayout{
        width: 100%;
        float: left;
    }
}