
.playerstat{
    text-shadow: -1px -1px 0 #c0bfbfc4, 1px -1px 0 rgb(36, 222, 219), -1px 1px 0 rgb(78, 78, 78), 1px 1px 0 rgb(235, 206, 47);
    font-style: italic;
    display: flex;
    
}

.stats{
    text-align: center;
    
}

.statanswers{
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 640px){
	/* Mobile Responsiveness CSS here */
	
}