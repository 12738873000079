
.background{
    background: url(../Sources/windowsblur.jpg) no-repeat center center fixed;
  background-size: "cover";
  height: 100vh;
  background-repeat:no-repeat; 
}

.input{
    width: 30px;
    float:right;
    margin-top:-4.4px;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
}

.user{
    width: 50px;
    border-radius: 30px;
}
.userLogo{
    width: 180px;
    border-radius: 100px;
    margin-left: 35px;
    margin-bottom: -40px;
}

.icons{
    width: 25px;
}
