.bg{
    /* background-image: url("../Sources/background.jpg"); */
    margin-left: 10px;
    border-radius: 10px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.splitScreenAbout {
    display: flex;
    flex-direction: row;    
    height:100%;
    padding-top: 60px;
}


.header{
    font-family: "Poppins";
    color: rgba(255, 255, 255, 0);
    text-shadow: -1px -1px 0 #c0bfbfc4, 1px -1px 0 rgb(36, 222, 219), -1px 1px 0 rgb(78, 78, 78), 1px 1px 0 rgb(235, 206, 47);

}

.font{
    
    color: #eeeef2;;
    font-size: 20px;
}
.emoji{
    width:20%
}

body{
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    
}


.resizer{
    width: 50%
}

@media screen and (max-width: 640px){
	/* Mobile Responsiveness CSS here */
	.resizer{
        width: 100%

    }
}